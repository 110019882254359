export default {

  //ANGLAIS 
  en: {
    Dashboard: 'Dashboard',
    Utilisateurs: 'Users',
    Compagnies: 'Companies',
    Address: 'Address',
    address: 'Address',
    Date_creation: 'Creation Date',
    Date_creation_error: 'The creation date field is required',
    Age: 'Age',
    Add: 'Add',
    Profil: 'Profile',
    PROFIL: 'PROFILE',
    Nom_Prenons: 'First & Last Name',
    mail: 'Email',
    username: 'Username',
    Phone: 'Phone',
    Compagnie: 'Company',
    Ajouter: 'Add',
    AJOUTER_UN: 'ADD ONE',
    AJOUTER_UNE: 'ADD ONE',
    MODIFICATION_DE_L: 'MODIFY THE',
    MODIFICATION_DE: 'MODIFY THE',
    MODIFICATION_D: 'MODIFY ONE',
    MODIFICATION_DU: 'MODIFY THE',
    UTILISATEUR: 'USER',
    UTILISATEURS: 'Users',
    Modifier: 'Edit',
    Supprimer: 'Delete',
    Action: 'Action',
    Rôle: 'Role',
    Nom: 'Last Name',
    Prénoms: 'First Name',
    Soumettre: 'Submit',
    Annuler: 'Cancel',
    Quit: 'Quit',
    enteteDelete: 'Confirm deletion?',
    confir_upd: 'Confirm update',
    Confirmer: 'Confirm',
    SuppressionUser1: 'The user was successfully deleted.',
    save_error: 'Save failed.',
    save_sucess_user: 'The user was successfully saved.',
    update_user: 'The user was successfully updated.',
    last_name_error: 'The last name field is required',
    first_name_error: 'The first name field is required',
    role_error: 'The role field is required',
    email_error: 'The email field is required',
    username_error: 'The username field is required',
    phoneNumber_error: 'The phone number field is required',
    nom_error: 'The last name field is required',
    SuppressionClient: 'The client was successfully deleted.',
    Company_delete: 'The company was successfully deleted.',
    resource_person: 'Resource Person',
    corporate_save: 'The company was successfully saved.',
    corporate_update: 'The company was successfully updated.',
    COMPAGNIE: 'COMPANY',
    voir: 'View',
    Envoyer_un_mail: 'Send an email',
    Envoyer: 'Send',
    Destinataire: 'Recipient',
    Précédent: 'Previous',
    Suivant: 'Next',
    Annuler_le_traitement_encours: 'Cancel ongoing processing',
    Mettre_encours_de_traitement: 'Start processing',
    message_error: 'The message failed.',
    messsage_envoyer: 'The message was successfully sent.',
    message_update: 'The message was successfully updated.',
    Déconnexion: 'Logout',
    MODIFICATION_DE_MOT_DE_PASSE: 'PASSWORD MODIFICATION',
    Entez_les_infomations_pour_continuer: 'Enter information to continue',
    Mot_de_passe: 'Password',
    Confirmation_mot_de_passe: 'Confirm password',
    veuillez_saisir_le_nouveau_mot_de_passe: 'Please enter the new password',
    MOT_DE_PASSE_CHANGÉ: 'PASSWORD CHANGED',
    ERROR_PASSWORD: 'ERROR',
    Modifier_mon_mot_de_passe: 'Change my password',
    Ouvrez_une_session_pour_commencer_à_utiliser_application: 'Log in to start using the application.',
    Se_souvenir_de_moi: 'Remember me',
    Mot_de_passe_oublié: 'Forgot password?',
    Connexion: 'Login',
    Récupérez_votre_mot_de_passe: 'Recover your password',
    recupération: 'Please enter your email address and we will send you instructions to reset your password.',
    Changer: 'Change',
    password_error: 'The password field is required',
    messages: 'Messages',
    Suppression: 'Deletion failed',
    cities: 'Cities',
    city: 'City',
    CITY: 'CITY',
    roles: 'Roles',
    role: 'Role',
    ROLE: 'ROLE',
    city_update: 'The city was successfully updated.',
    city_error: 'The city field is required',
    city_save: 'The city was successfully saved.',
    SuppressionCities: 'The city was successfully deleted.',
    role_save: 'The role was successfully saved.',
    role_update: 'The role was successfully updated.',
    role_Suppression: 'The role was successfully deleted.',
    age: 'Age',
    gender: 'Gender',
    pattern: 'Pattern',
    modifier: 'Edit',
    supprimer: 'Delete',
    full_name: 'Full Name',
    valider: 'Validate',
    hours: 'Hours',
    quantity: 'Quantity',
    etat: 'State',
    etat_error: 'The state field is required',
    age_error: 'The age field is required',
    gender_error: 'The gender field is required',
    Confirmer_validation: 'Confirm validation',
    birthDate: 'Birthday',
    birthDate_error: 'The birthday field is required',
    nationality: 'Nationality',
    NATIONALITY: 'NATIONALITY',
    nationalities: 'Nationalities',
    nationality_update: 'The nationality was successfully updated.',
    nationality_error: 'The nationality field is required',
    nationality_save: 'The nationality was successfully saved.',
    Suppressionnationality: 'The nationality was successfully deleted.',
    country: 'Country',
    countries: 'Countries',
    COUNTRY: 'COUNTRY',
    country_update: 'The country was successfully updated.',
    country_error: 'The country field is required',
    country_save: 'The country was successfully saved.',
    Suppressioncountry: 'The country was successfully deleted.',
    company: 'Company',
    companies: 'Companies',
    COMPANY: 'COMPANY',
    company_update: 'The company was successfully updated.',
    company_error: 'The company field is required',
    company_save: 'The company was successfully saved.',
    Suppressioncompany: 'The company was successfully deleted.',

    function: 'Function',
    functions: 'Functions',
    FUNCTION: 'FUNCTION',
    function_update: 'The function was successfully updated.',
    function_error: 'The function field is required',
    function_save: 'The function was successfully saved.',
    Suppressionfunction: 'The function was successfully deleted.',

    formation: 'Training',
    formations: 'Types of Training',
    FORMATION: 'TRAINING',
    formation_update: 'The training was successfully updated.',
    formation_error: 'The training field is required',
    formation_save: 'The training was successfully saved.',
    Suppressionformation: 'The training was successfully deleted.',

    insuranceCompanie: 'Insurance Company',
    insuranceCompanies: 'Insurances',
    INSURANCECOMPANIE: 'INSURANCE COMPANY',
    insuranceCompanie_update: 'The insurance company was successfully updated.',
    insuranceCompanie_error: 'The insurance company field is required',
    insuranceCompanie_save: 'The insurance company was successfully saved.',
    SuppressioninsuranceCompanie: 'The insurance company was successfully deleted.',

    equipment: 'PPE',
    equipments: 'PPE',
    EQUIPMENT: 'PPE',
    equipment_update: 'PPE was successfully updated.',
    equipment_error: 'The PPE field is required',
    equipment_save: 'PPE was successfully saved.',
    Suppressionequipments: 'PPE was successfully deleted.',

    equipmentType: 'Tool Type',
    equipmentTypes: 'Tool Types',
    EQUIPEMENTTYPE: 'TOOL TYPE',
    equipmentType_update: 'The tool type was successfully updated.',
    equipmentType_error: 'The tool type field is required',
    equipmentType_save: 'The tool type was successfully saved.',
    SuppressionequipmentTypes: 'The tool type was successfully deleted.',

    //liftingEquipments
    liftingEquipment: 'Lifting Tool',
    liftingEquipments: 'Lifting Tools',
    LIFTINGEQUIPMENT: 'LIFTING TOOLS',
    liftingEquipment_update: 'The lifting tool was successfully updated.',
    liftingEquipment_error: 'The lifting tool field is required',
    liftingEquipment_save: 'The lifting tool was successfully saved.',
    SuppressionliftingEquipment: 'The lifting tool was successfully deleted.',


    vehicle: 'Vehicle',
    vehicles: 'Vehicles',
    VEHICLES: 'VEHICLES',
    vehicle_update: 'The vehicle was successfully updated.',
    vehicle_error: 'The vehicle field is required',
    vehicle_save: 'The vehicle was successfully saved.',
    Suppressionvehicle: 'The vehicle was successfully deleted.',

    task: 'Task',
    tasks: 'Tasks',
    TASKS: 'TASKS',
    task_update: 'The task was successfully updated.',
    task_error: 'The task field is required',
    task_save: 'The task was successfully saved.',
    Suppressiontask: 'The task was successfully deleted.',

    staff: 'Personnel',
    staffs: 'Personnels',
    STAFFS: 'PERSONNEL',
    staff_update: 'The personnel was successfully updated.',
    staff_error: 'The personnel field is required',
    staff_save: 'The personnel was successfully saved.',
    Suppressionstaff: 'The personnel was successfully deleted.',

    security_level: 'Security Level',
    security_level_error: 'The security level field is required',
    norm: 'Standard',
    norm_error: 'The standard field is required',
    otherNorm: 'Other Standard',
    otherNorm_error: 'The other standard field is required',
    number: 'Serial Number',
    number_error: 'The serial number field is required',
    created_at: 'Registration Date',
    updated_at: 'Modification Date',
    description: 'Description',
    Inscrits: 'Registered',
    Localities: 'Localities',
    phone_error: 'The phone field is required',

    staffHasEquipment: 'PPE Assignment',
    staffHasEquipments: 'PPE Assignments',
    STAFFHASEQUIPMENTS: 'PPE ASSIGNMENTS',
    staffHasEquipment_update: 'The PPE assignment was successfully updated.',
    staffHasEquipmentStaff_error: 'The personnel field is required',
    staffHasEquipmentEquipments_error: 'The equipment(s) field is required',
    staffHasEquipment_save: 'The PPE assignment was successfully saved.',
    SuppressionstaffHasEquipment: 'The PPE assignment was successfully deleted.',

    team: 'Team',
    teams: 'Teams',
    TEAM: 'TEAM',
    team_update: 'The team was successfully updated.',
    team_error: 'The team field is required',
    team_save: 'The team was successfully saved.',
    Suppressionteam: 'The team was successfully deleted.',
    Staff_Formations: 'Training Matrix',

    site: 'Site',
    sites: 'Sites',
    SITES: 'SITES',
    site_update: 'The site was successfully updated.',
    site_error: 'The site field is required',
    site_save: 'The site was successfully saved.',
    Suppressionsite: 'The site was successfully deleted.',
    site_longitude: 'The longitude field is required',
    site_latitude: 'The latitude field is required',
    longitude: 'Longitude',
    latitude: 'Latitude',

    mission: 'Mission',
    missions: 'Missions',
    MISSIONS: 'MISSIONS',
    mission_update: 'The mission was successfully updated.',
    misson_error: 'The mission field is required',
    mission_save: 'The mission was successfully saved.',
    Suppressionmission: 'The mission was successfully deleted.',
    poNumber: 'Purchase Order Number',
    woReference: 'Work Order',
    numberOfSite: 'Number of Sites',
    startDate: 'Start Date',
    endDate: 'End Date',
    headOfMissions: 'Mission Leader',

    zone: 'Zone',
    zones: 'Zones',
    ZONES: 'ZONES',
    zone_update: 'The zone was successfully updated.',
    zone_error: 'The zone field is required',
    zone_save: 'The zone was successfully saved.',
    Suppressionzone: 'The zone was successfully deleted.',

    riskProfile_ad: 'Additional Risk Profile',
    riskProfile: 'Risk Profile',
    riskProfiles: 'Risk Profiles',
    RISKPROFILES: 'RISK PROFILES',
    riskProfile_update: 'The risk profile was successfully updated.',
    riskProfile_error: 'The risk profile field is required',
    riskProfile_save: 'The risk profile was successfully saved.',
    SuppressionriskProfile: 'The risk profile was successfully deleted.',
    journey: 'Journey',
    journeys: 'Journeys',
    JOURNEYS: 'JOURNEYS',
    journey_update: 'The journey was successfully updated.',
    journey_error: 'The journey field is required',
    journey_save: 'The journey was successfully saved.',
    Suppressionjourney: 'The journey was successfully deleted.',
    departurePlace: 'Departure Place',
    arrivalPlace: 'Arrival Place',
    departureTime: 'Departure Time',
    departurePlaceLongitude: 'Departure Place Longitude',
    departurePlaceLatitude: 'Departure Place Latitude',
    stopTime: 'Arrival Time',
    arrivalPlaceLongitude: 'Arrival Place Longitude',
    arrivalPlaceLatitude: 'Arrival Place Latitude',
    distance: 'Distance',
    duree: 'Duration',
    numberOfBreak: 'Number of Breaks',
    pause: 'Break',
    purpose: 'Purpose',
    breakStartTimes: 'Break Start Time',
    breakEndTimes: 'Break End Time',
    details: 'Details',
    interventionPlan: 'Intervention Plan',
    interventionPlanContact: 'Intervention Plan Contact',
    restriction: 'Restriction',
    applicator: 'Applicator',
    controller: 'Controller',
    readAndApproved: 'Read and Approved',

    measure: 'Safety Measure',
    measures: 'Safety Measures',
    MEASURES: 'SAFETY MEASURES',
    measure_update: 'The safety measure was successfully updated.',
    measure_error: 'The safety measure field is required',
    measure_save: 'The safety measure was successfully saved.',
    Suppressionmeasure: 'The safety measure was successfully deleted.',
    evacuationPlan: 'Evacuation Plan',
    evacuationPlans: 'Evacuation Plans',
    EVACUATIONPLANS: 'EVACUATION PLANS',
    evacuationPlan_update: 'The evacuation plan was successfully updated.',
    evacuationPlan_error: 'The evacuation plan field is required',
    evacuationPlan_save: 'The evacuation plan was successfully saved.',
    SuppressionevacuationPlan: 'The evacuation plan was successfully deleted.',
    evacuationType: 'Evacuation Type',
    priority1: 'Priority 1',
    priority2: 'Priority 2',
    priority3: 'Priority 3',
    nearestAgency1: 'Nearest Agency 1',
    nearestAgency2: 'Nearest Agency 2',
    nearestAgency3: 'Nearest Agency 3',
    service1: 'Service 1',
    service2: 'Service 2',
    service3: 'Service 3',
    phoneNumber1: 'Phone Number 1',
    phoneNumber2: 'Phone Number 2',
    phoneNumber3: 'Phone Number 3',
    distanceAndDurationFromThePlaceOfAccommodation1: 'Distance & duration from accommodation 1',
    distanceAndDurationFromThePlaceOfAccommodation2: 'Distance & duration from accommodation 2',
    distanceAndDurationFromThePlaceOfAccommodation3: 'Distance & duration from accommodation 3',
    documents: 'Documents',
    categorie: 'Category',
    categories: 'Categories',
    CATEGORIE: 'CATEGORY',
    categorie_update: 'The category was successfully updated.',
    categorie_error: 'The category field is required',
    categorie_save: 'The category was successfully saved.',
    Suppressioncategorie: 'The category was successfully deleted.',
    question: 'Question',
    questions: 'Questions',
    QUESTION: 'QUESTION',
    question_update: 'The question was successfully updated.',
    question_error: 'The question field is required',
    question_save: 'The question was successfully saved.',
    Suppressionquestion: 'The question was successfully deleted.',
    riskZoneAssessment: 'Exclusion Zone Assessment',
    riskZoneAssessments: 'Exclusion Zone Assessments',
    RISKZONEASSESSMENTS: 'EXCLUSION ZONE ASSESSMENTS',
    riskZoneAssessment_update: 'The exclusion zone assessment was successfully updated.',
    riskZoneAssessment_error: 'The exclusion zone assessment field is required',
    riskZoneAssessment_save: 'The exclusion zone assessment was successfully saved.',
    SuppressionriskZoneAssessment: 'The exclusion zone assessment was successfully deleted.',
    riskZones: 'Exclusion Zones',
    jobRiskAssessment_step: 'Risk Assessment',
    jobRiskAssessment: 'Job Risk Assessment',
    jobRiskAssessments: 'Job Risk Assessments',
    JOBRISKASSESSMENTS: 'JOB RISK ASSESSMENTS',
    jobRiskAssessment_update: 'The job risk assessment was successfully updated.',
    jobRiskAssessment_error: 'The job risk assessment field is required',
    jobRiskAssessment_save: 'The job risk assessment was successfully saved.',
    SuppressionjobRiskAssessment: 'The job risk assessment was successfully deleted.',
    initialRisk: 'Initial Risk',
    initialRiskSeverity: 'Initial Risk Severity',
    initialRiskProbability: 'Initial Risk Probability',
    comapasatoryMeasures: 'Compensatory Measures',
    residualRisk: 'Residual Risk',
    residualRiskSeverity: 'Residual Risk Severity',
    residualRiskProbability: 'Residual Risk Probability',
    nokiaValidation: 'Nokia Validation',
    nokiaComment: 'Nokia Comment',
    riskDetails: 'Risk Details',
    colorVrai: 'True Choice Color',
    colorFaux: 'False Choice Color',
    colorNa: 'No Choice Color',
    colorVrai_error: 'The true choice color field is required',
    colorFaux_error: 'The false choice color field is required',
    colorNa_error: 'The no choice color field is required',
    planification: 'Missions',
    planification_cloturer: 'Closed Missions',
    planification_NonCloturer: 'Ongoing Missions',
    mission_ref: 'Reference',
    preuve: 'Evidence(s)',
    activitie: 'Activity',
    activities: 'Activities',
    ACTIVITIE: 'ACTIVITY',
    activitie_update: 'The activity was successfully updated.',
    activitie_error: 'The activity field is required',
    activitie_save: 'The activity was successfully saved.',
    Suppressionactivitie: 'The question was successfully deleted.',
    risk: 'Risk',
    risks: 'Risks',
    RISK: 'RISK',
    risk_update: 'The risk was successfully updated.',
    risk_error: 'The risk field is required',
    risk_save: 'The risk was successfully saved.',
    Suppressionrisk: 'The risk was successfully deleted.',
    activity: 'Activity',
    riskAssessments: 'Risk Assessments',
    inactivation_teams: 'Teams Under Verification',
    activated_teams: 'Validated Teams',
    rejected_teams: 'Rejected Teams',
    inactivation_vehicles: 'Vehicles Under Verification',
    activated_vehicles: 'Validated Vehicles',
    rejected_vehicles: 'Rejected Vehicles',
    inactivation_staffs: 'Staff Under Verification',
    activated_staffs: 'Validated Staff',
    rejected_staffs: 'Rejected Staff',
    unclosedMissions: 'Ongoing Missions',
    enclosedMissions: 'Closed Missions',
    inactivation_liftingEquipments: 'Lifting Tools Under Verification',
    activated_liftingEquipments: 'Validated Lifting Tools',
    rejected_liftingEquipments: 'Rejected Lifting Tools',
    inactivation_equipments: 'PPE Under Verification',
    activated_equipments: 'Validated PPE',
    rejected_equipments: 'Rejected PPE',
    image_error: 'The image field is required',
    name_file1_3: 'The file is required',
    project: 'Project',
    projects: 'Projects',
    PROJECTS: 'PROJECTS',
    project_update: 'The project was successfully updated.',
    project_error: 'The project field is required',
    project_save: 'The project was successfully saved.',
    Suppressionproject: 'The project was successfully deleted.',
    pessNokia: 'PESS NOKIA',
    rom: 'ROM',
    qhsNokia: 'QHS NOKIA',
    leadProject: 'PROJECT LEADER',
    name: 'Full Name',
    pessNokia_error: 'The PESS Nokia field is required',
    rom_error: 'The ROM field is required',
    qhsNokia_error: 'The QHS Nokia field is required',
    name_error: 'The full name field is required',
    leadProject_error: 'The lead project field is required',
    description_error: 'The description field is required',
    raison: 'Reason',
    raison_error: 'The project field is required',
    scheduled_date: 'Scheduled Date',
    scheduled_date_error: 'The project field is required',
    meetings: 'Meetings',
    is_satisfied: 'Satisfaction',
    Suppressionmeeting: 'The meeting was successfully deleted.',
    reserve_comment: 'Comment',
    reserve_type: 'Reserve Type',
    reserve_comment_error: 'The comment field is required',
    reserve_type_error: 'The reserve type field is required',
    purchase: 'Purchase Order',
    purchase_error: 'The purchase order field is required',
    purchase_save: 'The purchase order was successfully saved.',
    purchase_update: 'The purchase order was successfully updated.',
    Suppressionpurchases: 'The purchase order was successfully deleted.',
    orderOfWorks: 'Work Order',
    orderOfWork_error: 'The work order field is required.',
    orderOfWork_save: 'The work order was successfully saved.',
    orderOfWork_update: 'The work order was successfully updated.',
    SuppressionorderOfWork: 'The work order was successfully deleted.',
    validations: 'Validations',
    libraries: 'Risk Libraries',
    library: 'Risk Library',
    LIBRARY: 'RISK LIBRARY',
    library_error: 'The risk library is required.',
    library_save: 'The risk  library was successfully saved.',
    library_update: 'The risk library was successfully updated.',
    Suppressionlibrary: 'The risk library was successfully deleted.',
    teamLeader: 'Subcontractor Team Leader',
    leader: 'Leader',
    securityQuestion: 'Enumerate a security risk',
    securityQuestions: 'Security Questionnaire',
    SECURITYQUESTION: 'SECURITY QUESTIONNAIRE',
    securityQuestions_error: 'Enumerating the security risk is required.',
    securityQuestions_save: 'The security risk was successfully saved.',
    securityQuestions_update: 'The security risk was successfully updated.',
    SuppressionsecurityQuestions: 'The security risk was successfully deleted.',
    siteGroup: 'Type of security Evaluation',
    securityEvaluation: 'Security Evaluation',
    securityEvaluations: 'Security Evaluations',
    SECURITYEVALUATION: 'SECURITY EVALUATION',
    securityEvaluations_error: 'The security evaluation is required.',
    securityEvaluations_save: 'The security evaluation was successfully saved.',
    securityEvaluations_update: 'The security evaluation was successfully updated.',
    SuppressionsecurityEvaluations: 'The safety evaluation was successfully deleted.',
    eva_instable: 'INSTABILITY ASSESSMENT',
    eva_vunerable: 'VULNERABILITY ASSESSMENT',
    eva_consequance: 'CONSEQUENCE ASSESSMENT',
    eva_probabilite: 'PROBABILITY ASSESSMENT',
    timing: 'Timing',
    feasibility: 'Feasibility',
    achievableInMoreFavorablePeriod: 'Achievable in a more favorable period',
    notFeasibleOverLongPeriod: 'Not feasible over a long period',
    initializeCmt: 'Initialize a CMT',
    comment: 'Recommendation',
    score: 'Score',
    final_result: 'Final Result',
    mission_error: 'The mission field is required',
    timing_error: 'The timing field is required',
    siteGroup_error: 'The type of evaluation field is required',
    comment_error: 'The recommendation field is required',
    securityEvalution_error: 'The security evaluation is required.',
    securityEvalution_save: 'The security evaluation was successfully saved.',
    securityEvalution_update: 'The security evaluation was successfully updated.',
    SuppressionsecurityEvalution: 'The security evaluation was successfully deleted.',
    reporting: 'Reporting',
    validate_trajet: 'Journey Validation',
    mission_status: 'Mission Status',
    Date_début:'Date start',
    Date_fin:'Date end',

    //New translation '2nd batch'

    phoneNumber: 'The phone field is required',
    comfirm_validation: 'Confirm validation?',
    valide_equiment: 'Equipment successfully validated.',
    echec_equiment: 'Validation failed.',
    rejet_equiment: 'Rejection successfully validated',
    Voir: 'Detail',
    autre_norme: 'Other standard',
    changer: 'Change',
    effacer: 'Delete',
    ajouter_Image: 'Add Image',
    modifier_Image: 'Edit Image',
    valider_equipement: 'Validate equipment',
    rejeter_equipement: 'Reject equipment',
    rapport: 'Report',
    details_du_rejet: 'Rejection details',
    ajouter_votre_commentaire: 'Add your comment',
    type: 'Type',
    num_plaque: 'Plate number',
    carte_grise: 'Vehicle registration',
    modele: 'Model',
    image: 'Image(s)',
    valider_véhicule: 'Validate vehicle',
    rejeter_véhicule: 'Reject vehicle',
    images_vehicle: 'Vehicle images',
    Nombre_ceinture_sécurité_02: 'Number of 02-point seat belts',
    Nombre_ceinture_sécurité_03: 'Number of 03-point seat belts',
    air_bague: 'Airbag',
    date_mise_circulation: 'Date of first registration',
    compagnie_assurance: 'Insurance company',
    type_assurance: 'Insurance type',
    num_assurance: 'Insurance number',
    date_expiration: 'Expiry date',
    dernière_visite_technique: 'Last technical inspection',
    date_visite_technique_venir: 'Upcoming technical inspection date',
    date_maitenance_venir: 'Upcoming maintenance date',
    suivi_ivms: 'IVMS tracking',
    Kilomètrage: 'Mileage',
    rapport_ivms: 'IVMS report',
    carnet_entretien: 'Maintenance logbook',
    Ajout_Nouvel: 'Add New',
    choisir: 'Choose',
    type_error: 'The type field is required',
    plateNumber_error: 'The plate number field is required',
    greyCard_error: 'The vehicle registration field is required',
    numberOf03PointsSeatBelt_error: 'The 03-point seat belt count field is required',
    numberOf02PointsSeatBelt_error: 'The 02-point seat belt count field is required',
    frontAirbag_error: 'The Airbag field is required',
    firtsReleaseDate_error: 'The first release date field is required',
    insuranceCompany_error: 'The insurance company field is required',
    insurancePolicyNumber_error: 'The insurance number field is required',
    typeOfInsuranceContract_error: 'The insurance contract type field is required',
    expiryDate_error: 'The expiry date field is required',
    lastTechnicalVisitDate_error: 'The last technical inspection date field is required',
    nextTechnicalVisitDate_error: 'The next technical inspection date field is required',
    ivmsReport_error: 'The IVMS report field is required',
    dateOfTheNextMaintenanceVisit_error: 'The next maintenance visit date field is required',
    ivmsTracker_error: 'The IVMS tracking field is required',
    model_error: 'The model field is required',
    killometerOnTheDashboard_error: 'The dashboard mileage field is required',
    maintenanceNotebook_error: 'The maintenance logbook field is required',
    valide_vehicule: 'Vehicle successfully validated.',
    echec_validation_vehicule: 'Vehicle validation failed.',
    rejete_vehicule: 'Vehicle rejection successfully validated.',
    echec_rejet_vehicule: 'Vehicle rejection failed.',
    valider_personnel: 'Validate personnel',
    rejeter_personnel: 'Reject personnel',
    contractType: 'Contract type',
    cardType: 'Card type',
    idCardOrPassportNumber: 'ID card or passport number',
    externalStaffOwnedByAnotherCompany: 'External staff owned by another company',
    seniorityInTheCompany: 'Seniority in the company',
    seniorityInTheFunction: 'Seniority in the function',
    externalCompanyName: 'External company name',
    externalCompanyContractReference: 'External company contract reference',
    drivingLicenseID: 'Driving license ID',
    seniorityInDriving: 'Seniority in driving',
    medicalCheck: 'Medical check',
    medicalCheckDate: 'Medical check date',
    preuve_medicale: 'Medical proof',
    informations_formation: 'Training information',
    dateOfObtainings: 'Training date',
    trainerOrganisms_tab: 'Training organizations',
    idCertificateReferences: 'Certificate references',
    validityDates: 'Validity dates',
    dateOfTheNextUpgrades: 'Next upgrade date',
    date_formation: 'Training date',
    date_obtention: 'Obtaining date',
    organisme_formateur: 'Training organization',
    référence_certificat_identification: 'Certificate identification reference',
    date_validite: 'Validity date',
    prochaine_mise_niveau: 'Next upgrade date',
    valide_staff: 'Personnel successfully validated.',
    echec_validation_staff: 'Personnel validation failed.',
    rejete_staff: 'Personnel rejection successfully validated.',
    echec_rejet_staff: 'Personnel rejection failed.',
    formation_add: 'Training added to the list',
    formation_add_error: 'The training already exists in the list',
    formation_delete: 'Training removed from the list',
    formation_update_up: 'Training updated',
    formation_vide: 'Training list is empty',
    fullName_error: 'The full name field is required',
    externalCompanyContractReference_error: 'The external company contract reference field is required',
    medicalCheckDate_error: 'The medical check date field is required',
    contractType_error: 'The contract type field is required',
    cardType_error: 'The card type field is required',
    idCardOrPassportNumber_error: 'The ID card or passport number field is required',
    externalStaffOwnedByAnotherCompany_error: 'The external staff owned by another company field is required',
    birthdate_error: 'The birthdate field is required',
    seniorityInTheCompany_error: 'The seniority in the company field is required',
    job_function_error: 'The job function field is required',
    seniorityInTheFunction_error: 'The seniority in the function field is required',
    externalCompanyName_error: 'The external company name field is required',
    drivingLicenseID_error: 'The driving license ID field is required',
    seniorityInDriving_error: 'The seniority in driving field is required',
    medicalCheck_error: 'The medical check field is required',
    formations_error: 'The training field is required',
    dateOfTrainings_error: 'The training date field is required',
    dateOfObtainings_error: 'The obtaining date field is required',
    trainerOrganisms_error: 'The training organization field is required',
    idCertificateReferences_error: 'The certificate identification reference field is required',
    validityDates_error: 'The validity date field is required',
    dateOfTheNextUpgrades_error: 'The next upgrade date field is required',
    add_personne: 'Add personnel',
    update_personne: 'Edit personnel',
    detail_personnne: 'Personnel details',
    filtre: 'Filter',
    ajouter_vehicle: 'Add a vehicle',
    modifier_vehicle: 'Edit a vehicle',
    details_vehicle: 'Vehicle details',
    valider_equipe: 'Validate team',
    rejeter_equipe: 'Reject team',
    ajouter_equipe: 'Add a team',
    modifier_equipe: 'Edit a team',
    details_equipe: 'Team details',
    valide_equipe: 'Team successfully validated.',
    echec_validation_equipe: 'Team validation failed.',
    rejete_equipe: 'Team rejection successfully validated.',
    echec_rejet_equipe: 'Team rejection failed.',
    Suppressionequipe: 'The team was successfully deleted.',
    equipe_error: 'The team is required.',
    equipe_save: 'The team was successfully saved.',
    equipe_update: 'The team was successfully updated.',
    equipe_name: 'Team name',
    Valider: 'Validate',
    teamLeader_error: 'The subcontractor team leader field is required',
    ajouter_projet: 'Add a project',
    modifier_projet: 'Edit a project',
    details_projet: 'Project details',
    creation_bon_com: 'Create purchase order and work order',
    bon_comds: 'Purchase order(s)',
    bon_comd: 'Purchase order',
    ords_travail: 'Work order(s)',
    ord_travail: 'Work order',
    info_assignation: 'Assignment information',
    sous_traitant: 'Subcontractor',
    assignations: 'Assignment(s)',
    save_site_up_error: 'This row (site, company, and purchase order) already exists in the list. Please delete it for security reasons and recreate it',
    save_task_add_error: 'This row (site, company, and purchase order) already exists in the list. Please modify the added task(s) in the list',
    site_deleted_liste: 'Site removed from the list',
    site_existe_liste: 'The site group already exists in the list',
    site_add_liste: 'Site group added to the list',
    site_verification_liste: 'Verify information',
    up_formation: 'Training modified',
    add_site_pro: 'Site added to the list',
    add_site_pro_add: 'The site already exists in the list',
    site_obligatoire: 'The site field is required',
    ord_travail_error: 'The work order number field is required',
    bon_comd_error: 'The purchase order number field is required',
    voir_mission: 'View mission',
    modifier_mission: 'Edit mission',
    supprimer_mission: 'Delete mission',
    confirmer_vehicule: 'Confirm vehicle(s)',
    creation_mission: 'Create mission',
    etape_1: 'Step 1',
    continuer_trajet: 'Continue the route',
    voir_trajet: 'View the route',
    modifier_trajet: 'Edit the route',
    supprimer_trajet: 'Delete the route',
    creation_trajet: 'Create a route',
    etape_2: 'Step 2',
    voir_evacuation: 'View evacuation plan',
    modifier_evacuation: 'Edit evacuation plan',
    supprimer_evacuation: 'Delete evacuation plan',
    creation_evacuation: 'Create evacuation plan',
    etape_3: 'Step 3',
    voir_zone_exclusion: 'View exclusion zone',
    modifier_zone_exclusion: 'Edit exclusion zone',
    supprimer_zone_exclusion: 'Delete exclusion zone',
    creation_zone_exclusion: 'Create exclusion zone',
    etape_4: 'Step 4',
    voir_evaluation_risque: 'View risk assessment',
    modifier_evaluation_risque: 'Edit risk assessment',
    supprimer_evaluation_risque: 'Delete risk assessment',
    creation_evaluation_risque: 'Create risk assessment',
    etape_5: 'Step 5',
    non_démarée: 'Not started',
    rejetée: 'Rejected',
    opération_échouée: 'Operation failed',
    confirmer_clôture: 'Confirm closure?',
    rejeter_mission: 'Reject mission',
    confirmer_rejet: 'Confirm rejection?',
    mission_rejete: 'Mission rejected',
    échoué: 'Failed',
    confirmation_trajet: 'Route confirmation',
    confirmer_validation: 'Confirm validation?',
    trajet_validé: 'Route validated',
    rejet_trajet: 'Route rejected',
    trajet_rejeté: 'Route rejected',
    suppression_mission: 'Confirm mission deletion?',
    suppression_valid_mission: 'The mission was successfully deleted.',
    suppression_error_mission: 'Mission deletion failed.',
    suppression_evacuation: 'Confirm evacuation plan deletion?',
    suppression_valid_evacuation: 'The plan was successfully deleted.',
    suppression_error_evacuation: 'Evacuation deletion failed.',
    suppression_trajet: 'Confirm route deletion?',
    suppression_valid_trajet: 'The route was successfully deleted.',
    suppression_error_trajet: 'Route deletion failed.',
    suppression_zone_exclusion: 'Confirm exclusion zone deletion?',
    suppression_valid_zone_exclusion: 'The risk zone assessment was successfully deleted.',
    suppression_error_zone_exclusion: 'Exclusion zone deletion failed.',
    suppression_evaluation_risque: 'Confirm risk assessment deletion?',
    suppression_valid_evaluation_risque: 'The job risk assessment was successfully deleted.',
    suppression_error_evaluation_risque: 'Risk assessment deletion failed.',
    mission_clôturée: 'Mission closed',
    carte: 'Card',
    alerte: 'Alert',
    valider_risques: 'Validate risks',
    rejet_trajet_: 'Reject route (Nokia)',
    clôturer_mission: 'Close mission',
    valider_trajet_: 'Validate route (Nokia)',
    voir_evaluation_securitaire: 'View safety assessment',
    info_equipe: 'Team information',
    prod_op: 'Operational procedures',
    bref_sec: 'Safety briefing',
    pres_hse: 'HSE toolbox presentation',
    confirmation: 'Confirmation',
    otherMeansOTransport: 'Other means of transport',
    fiche_insp: 'Vehicle inspection sheet(s) and other required documents',
    name_file: 'File name',
    reunion_etat: 'Satisfactory meeting',
    demande_etat: 'Check if the request was fulfilled.',
    comite_sécurite: 'Safety committee',
    question_modif_equipe: 'Do you want to modify the selected team?',
    question_ajout_equipe: 'Do you want to add a new team?',
    comfirm_vehicule: 'Vehicle confirmation',
    retour: 'Back',
    name_file1_3_error: 'The file is required',
    poNumber_error: 'The purchase order number field is required',
    woReference_error: 'The work order field is required',
    numberOfSite_error: 'The number of sites field is required',
    endDate_error: 'The end date field is required',
    startDate_error:'The start date field is required',
    tasks_missions_error: 'The task field is required',
    headOfMissions_error: 'The mission leader field is required',
    pro_operationnelle_oui: 'Operational procedures confirmed',
    pro_operationnelle_non: 'Operational procedures not confirmed',
    bref_sec_conf_oui: 'Safety briefing confirmed',
    bref_sec_conf_non: 'Safety briefing not confirmed',
    pre_boite_oui: 'Toolbox talk HSE confirmed',
    pre_boite_non: 'Toolbox talk HSE not confirmed',
    send_mail: 'Email sent',
    comf_vehicule: 'Vehicle confirmed',
    comf_vehicule_error: 'CONFIRMATION ERROR',
    delete_file: 'File removed from list',
    save_mission: 'Mission successfully saved.',
    update_mission: 'Mission successfully updated.',
    echec_mission_save: 'MISSION SAVE FAILED',
    save_error_team: 'REGISTRATION ERROR',
    detail_pause: 'Pause details',
    longitude_error: 'Invalid longitude.',
    latitude_error: 'Invalid latitude.',
    definition_pause: 'Define the pause',
    il_vous_reste: 'You have',
    pause_obligatoire: 'mandatory pause(s) to plan',
    heure_definie: 'The defined time is not allowed',
    pauses: 'Pause(s)',
    interventionPlanContact_error: 'The intervention plan contact field is required',
    departurePlace_error: 'The departure place field is required',
    departurePlaceLatitude_error: 'The departure place latitude field is required',
    arrivalPlace_error: 'The arrival place field is required',
    arrivalPlaceLongitude_error: 'The arrival place longitude field is required',
    arrivalPlaceLatitude_error: 'The arrival place latitude field is required',
    departureTime_error: 'The departure time field is required',
    stopTime_error: 'The arrival time field is required',
    distance_error: 'The distance field is required',
    duree_error: 'The duration field is required',
    numberOfBreak_error: 'The number of breaks field is required',
    pause_error: 'The pause field is required',
    purpose_error: 'The purpose field is required',
    sites_error: 'The site field is required',
    breakStartTimes_error: 'The break start time field is required',
    breakEndTimes_error: 'The break end time field is required',
    details_error: 'The details field is required',
    pause_add: 'Pause added to the list',
    error: 'ERROR',
    attention: 'ATTENTION',
    evacuation_save: 'Plan successfully saved.',
    evacuation_update: 'Plan successfully updated.',
    Suppressionevacuation: 'Plan successfully deleted.',
    site_evalue: 'Site(s) evaluated',
    all_delete: 'Delete all',
    voir_evaluation: 'View safety assessment of the operation zone',
    add_eva_sec: 'Add a safety assessment for the operation zone',
    edit_sec: 'Edit the safety assessment of the operation zone',
    risque_emploie: 'Job risk assessment successfully deleted.',
    echec_risque_emploie: 'Deletion failed.',
    risque_emploie_save: 'Job risk assessment successfully saved.',
    risque_emploie_update: 'Job risk assessment successfully updated.',
    texte_validation_evaluation: 'Confirm job risk assessment validation.',
    validation_succes: 'Validation successful.',
    validation_echec: 'Validation failed.',
    nombre_pause_suff: 'Sufficient number of pauses',
    nombre_pause_suppr: 'Pause removed from list',
    pause_update: 'Pause updated',
    pause_one_texte: 'You are entitled to',
    pause_tow_texte: 'mandatory pause(s) to plan',
    oui: 'Yes',
    non: 'No',
    select_update: 'Select a site to edit',
    select_show: 'Select a site for details',
    questionnaire: 'Questionnaires',
    quesion_update: 'Questionnaire updated',
    list_site: 'The list of validated site(s) is empty',
    libelle_error: 'Label field is required',
    site_insuf: 'The list of evaluated site(s) is insufficient',
    risk_residuel: 'Residual risks',
    risk_r1: 'Risk (R)',
    risk_r2: 'is calculated as the product of',
    risk_r3: 'Probability (P)',
    risk_r4: 'and',
    risk_r5: 'Consequence (C)',
    axe_vertical: 'Vertical axis = Consequence (C)',
    axe_horizontal: 'Horizontal axis = Probability (P)',
    graduation: 'Graduation: [1-5]',
    legende: 'LEGEND:',
    negligeable_1: '1 = Negligible',
    modere_2: '2 = Moderate',
    eleve_3: '3 = High',
    considerable_4: '4 = Considerable',
    extreme_5: '5 = Extreme',

    ajouter_votre_commentaire_QHS:'Add your comment QHS',
    ajouter_votre_commentaire_PESS:'Add your comment PESS',
    ajouter_votre_commentaire_CHEF:'Add your comment PROJECT LEADER',
    fichier_obligatoire:'File require',
    fichier:'File',
    manager:'Manager subcontractor',
    manager_error:'The manager subcontractor field is required',
    comonTransport:'Public transportation',
    comonTransportDetails:'Transportation details',

    comonTransport_error:'The public transport field is mandatory ',
    comonTransportDetails_error:'The transport details field is mandatory',

    rental:'Rental car',
    rentalCompany:'Rental company',
    rentalCompanyContratReference :'Rental company contract reference',

    rental_error:'Rental car field is obligation ',
    rentalCompany_error:'Rental company is obligated',
    rentalCompanyContratReference_error :'Reference of the rental company contract is obligatory',

    Assignations:'Assignment',
    ajouter_assignation:'Add/Edit Assignment',
    modifiction_assignation:'Edit Assignment',
    details_assignation:'Assignment Details',
    add_modifier:'Add/Edit'
  },

  //FRANCAIS 

  fr: {
    Dashboard: 'Tableau de bord',
    Utilisateurs: 'Utilisateurs',
    Compagnies: 'Entreprises',
    Address:'Adresse',
    address:'Adresse',
    Date_creation:'Date de création',
    Date_creation_error:'Le champ date de création est obligatoire',
    Age :'Âge',
    Add: 'Ajouter',
    Profil: 'Profil',
    PROFIL: 'PROFIL',
    Nom_Prenons: 'Nom & Prénoms',
    mail: 'E-mail',
    username:'Nom d\'utilisateur',
    Phone: 'Téléphone',
    Compagnie: 'Entreprise',
    Ajouter: 'Ajouter',
    AJOUTER_UN: 'AJOUTER UN',
    AJOUTER_UNE: 'AJOUTER UNE',
    MODIFICATION_DE_L: 'MODIFIER L\'',
    MODIFICATION_DE: 'MODIFIER LA',
    MODIFICATION_D: 'MODIFIER D\'UN',
    MODIFICATION_DU: 'MODIFIER LE',
    UTILISATEUR: 'UTILISATEUR',
    UTILISATEURS: 'Utilisateurs',
    Modifier: 'Modifier',
    Supprimer: 'Supprimer',
    Action: 'Action',
    Rôle: 'Rôle',
    Nom: 'Nom',
    Prénoms: 'Prénoms',
    Soumettre: 'Soumettre',
    Annuler: 'Annuler',
    Quit:'Quitter',
    enteteDelete: 'Confirmer la suppression?',
    confir_upd:'Confirmer la mise à jour',
    Confirmer :'confirmer',
    SuppressionUser1:'L\'utilutilsateur est supprimé avec succès.',
    save_error:'Enrégistrement échoué.',
    save_sucess_user:'L\'utilisateur est enrégistré avec succès.',
    update_user:'L\'utilsateur est modifié avec succès.',
    last_name_error:  'Le champ nom est obligatoire',
    first_name_error: 'Le champ prénoms est obligatoire',
    role_error:  'Le champ role est obligatoire',
    email_error: 'Le champ e-mail est obligatoire',
    username_error: 'Le champ nom d\'utilisateur est obligatoire',
    phoneNumber_error:'Le champ téléphone est obligatoire',
    nom_error: 'Le champ nom est obligatoire',
    SuppressionClient:'La client est supprimé avec succès.',
  
 
    Company_delete :'L\'entreprise est supprimée avec succès.',
    resource_person:'Personne ressource',
    corporate_save: 'L\'entreprise est enrégistrée avec succès.',
    corporate_update : 'L\'entreprise est modifiée avec succès.',
    COMPAGNIE:'ENTREPRISE',
 
    voir:'Voir',
    Envoyer_un_mail:'Envoyer un mail',
    Envoyer:'Envoyer',
    Destinataire:'Destinataire',
  
    Précédent:'Précédent',
    Suivant:'Suivant',
    Annuler_le_traitement_encours:'Annuler le traitement encours',
    Mettre_encours_de_traitement:'Mettre encours de traitement',
    message_error:'Le message à échouer.',
    messsage_envoyer:'Le message est envoyé avec succès.',
    message_update:'Le message est modifié avec succès.',
    Déconnexion:'Déconnexion',
    MODIFICATION_DE_MOT_DE_PASSE:'MODIFICATION DE MOT DE PASSE',
    Entez_les_infomations_pour_continuer:'Entez les infomations pour continuer',
    Mot_de_passe:'Mot de passe',
    Confirmation_mot_de_passe:'Confirmation mot de passe',
    veuillez_saisir_le_nouveau_mot_de_passe:'veuillez saisir le nouveau mot de passe',
    MOT_DE_PASSE_CHANGÉ:'MOT DE PASSE CHANGÉ',
    ERROR_PASSWORD:'ERREUR',
    Modifier_mon_mot_de_passe:'Modifier mon mot de passe',
    Ouvrez_une_session_pour_commencer_à_utiliser_application:'Ouvrez une session pour commencer à utiliser l\'application.',
    Se_souvenir_de_moi:'Se souvenir de moi',
    Mot_de_passe_oublié:'Mot de passe oublié?',
    Connexion:'Connexion',
    Récupérez_votre_mot_de_passe:'Récupérez votre mot de passe',
    recupération:'Veuillez saisir votre adresse e-mail et nous vous enverrons des instructions pour réinitialiser votre mot de passe.',
    Changer:'Changer',
    password_error:'Le champ mot de passe est obligatoire',
    messages:'Messages',
    Suppression:'Suppression échouée',

    cities:'Villes',
    city:'Ville',
    CITY:'VILLE',
 
    roles:'Rôles',
    role:'Role',
    ROLE:'ROLE',

    city_update:'La ville est modifiée avec succès.',
    city_error:'Le champ ville  est obligatoire',
    city_save:'La ville est enrégistrée avec succès.',
    SuppressionCities:'La ville est supprimée avec succès.',


    role_save:'Le rôle est enrégistré avec succès.',
    role_update:'Le rôle est modifié avec succès.',
    role_Suppression:'Le rôle est supprimé avec succès.',

    age:'Âge',
    gender:'Sexe',
    pattern:'Motif',
 
    modifier:'Modifier',
    supprimer:'Supprimer',
    full_name:'Nom complet',
    valider:'valider',
    hours:'heures',

    quantity:'Quantité',


    etat :'État',
 
    etat_error:'Le champ état est obligatoire',
    age_error:'Le champ âge est obligatoire',
    gender_error:'Le champ sexe est obligatoire',

  
    Confirmer_validation:'Confirmer la validation',


    birthDate:'Date de naissance',
    birthDate_error:'Le champ date de naissance est obligatoire',

    nationality:'Nationalité',
    NATIONALITY:'NATIONALITÉ',
    nationalities:'Nationalités',
    nationality_update:'La nationalité est modifiée avec succès.',
    nationality_error:'La champ nationalité  est obligatoire',
    nationality_save:'La nationalité est enrégistrée avec succès.',
    Suppressionnationality:'La nationalité est supprimée avec succès.',

    country:'Pays',
    countries:'Pays',
    COUNTRY:'PAYS',
    country_update:'Le pays est modifié avec succès.',
    country_error:'Le champ pays  est obligatoire',
    country_save:'Le pays est enrégistré avec succès.',
    Suppressioncountry:'Le pays est supprimé avec succès.',

    company:'Compagnie',
    companies:'Compagnies',
    COMPANY:'COMPAGNIE',
    company_update:'La compagnie est modifiée avec succès.',
    company_error:'Le champ compagnie est obligatoire',
    company_save:'La compagnie est enrégistrée avec succès.',
    Suppressioncompany:'La compagnie est supprimée avec succès.',


    function:'Fonction',
    functions:'Fonctions',
    FUNCTION:'FONCTION',
    function_update:'La fonction est modifiée avec succès.',
    function_error:'Le champ fonction est obligatoire',
    function_save:'La fonction est enrégistrée avec succès.',
    Suppressionfunction:'La fonction est supprimée avec succès.',

    formation:'Formation',
    formations:'Type de Formations',
    FORMATION:'FORMATION',
    formation_update:'La formation est modifiée avec succès.',
    formation_error:'Le champ formation est obligatoire',
    formation_save:'La formation est enrégistrée avec succès.',
    Suppressionformation:'La formation est supprimée avec succès.',

    ///insuranceCompanies,

    insuranceCompanie:'Compagnie d\'assurance',
    insuranceCompanies:'Assurances',
    INSURANCECOMPANIE:'COMPAGNIE D\'ASSURANCE',
    insuranceCompanie_update:'La compagnie d\'assurance est modifiée avec succès.',
    insuranceCompanie_error:'Le champ compagnie d\'assurance est obligatoire',
    insuranceCompanie_save:'La compagnie d\'assurance est enrégistrée avec succès.',
    SuppressioninsuranceCompanie:'La compagnie d\'assurance est supprimée avec succès.',

    //equipments
    equipment:'EPI',
    equipments:'EPI',
    EQUIPMENT:'EPI',
    equipment_update:'EPI est modifié avec succès.',
    equipment_error:'Le champ EPI est obligatoire',
    equipment_save:'EPI est enrégistré avec succès.',
    Suppressionequipments:'EPI est supprimé avec succès.',

    equipmentType:'Type d\'outil',
    equipmentTypes:'Types d\'outils',
    EQUIPEMENTTYPE:'TYPE D\'OUTIL',
    equipmentType_update:'Le type d\'outil est modifié avec succès.',
    equipmentType_error:'Le champ type d\'outil est obligatoire',
    equipmentType_save:'Type d\'outil est enrégistré avec succès.',
    SuppressionequipmentTypes:'Type d\'outil est supprimé avec succès.',
    //liftingEquipments

    liftingEquipment:'Outil de levage',
    liftingEquipments:'Outils de levage',
    LIFTINGEQUIPMENT:'OUTILS DE LEVAGE',
    liftingEquipment_update:'L\'outil de levage est modifié avec succès.',
    liftingEquipment_error:'Le champ outil de levage est obligatoire',
    liftingEquipment_save:'Outil de levage est enrégistré avec succès.',
    SuppressionliftingEquipment:'L\'outil de levage est supprimé avec succès.',

    vehicle:'Véhicule',
    vehicles:'Véhicules',
    VEHICLES:'VÉHICULES',
    vehicle_update:'Véhicule est modifié avec succès.',
    vehicle_error:'Le champ véhicule est obligatoire',
    vehicle_save:'le véhicule est enrégistré avec succès.',
    Suppressionvehicle:'Le véhicule est supprimé avec succès.',

    task:'Tâche',
    tasks:'Tâches',
    TASKS:'TÂCHES',
    task_update:'Tâche est modifiée avec succès.',
    task_error:'Le champ tâche est obligatoire',
    task_save:'la tâche est enrégistrée avec succès.',
    Suppressiontask:'La tâche est supprimée avec succès.',

    staff:'Personnel',
    staffs:'Personnels',
    STAFFS:'PERSONNEL',
    staff_update:'Personnel est modifié avec succès.',
    staff_error:'Le champ personnel est obligatoire',
    staff_save:'le personnel est enrégistré avec succès.',
    Suppressionstaff:'Le personnel est supprimé avec succès.',

    security_level:'Niveau de sécurité',
    security_level_error:'Le champ niveau de sécurité est obligatoire',
    norm:'Norme',
    norm_error:'Le champ norme est obligatoire',
    otherNorm:'Autre norme',
    otherNorm_error:'Le champ autre norme est obligatoire',
    number:'Numéro de série',
    number_error:'Le champ identifiant est obligatoire',
    created_at:'Date d\'enrégistrement',
    updated_at:'Date de modification',
    description:'Description',


    Inscrits:'Inscrits',
    Localities:'Localités',

    phone_error:'Le champ téléphone est obligatoire',

    staffHasEquipment:'Affectation d\'EPI',
    staffHasEquipments:'Affectations d\'EPI',
    STAFFHASEQUIPMENTS:'AFFECTATIONS D\'EPI',
    staffHasEquipment_update:'L\'affectation d\'EPI est modifiée avec succès.',
    staffHasEquipmentStaff_error:'Le champ personnel est obligatoire',
    staffHasEquipmentEquipments_error:'Le champ équipemt(s) est obligatoire',
    staffHasEquipment_save:'L\'affectation d\'EPI est enrégistrée avec succès.',
    SuppressionstaffHasEquipment:'L\'affectation d\'EPI est supprimée avec succès.',

    team:'Équipe',
    teams:'Équipes',
    TEAM:'Équipe',
    team_update:'L\'équipe est modifié avec succès.',
    team_error:'Le champ équipe est obligatoire',
    team_save:'l\'équipe est enrégistré avec succès.',
    Suppressionteam:'L\'équipe est supprimé avec succès.',

    Staff_Formations:'Matrice de Formations',
  
    site:'Site',
    sites:'Sites',
    SITES:'SITES',
    site_update:'Site est modifié avec succès.',
    site_error:'Le champ site est obligatoire',
    site_save:'le site est enrégistré avec succès.',
    Suppressionsite:'Le site est supprimé avec succès.',
    site_longitude:'Le champ longitude est obligatoire',
    site_latitude:'Le champ latitude est obligatoire',
    longitude:'Longitude',
    latitude:'Latitude',

    mission:'Mission',
    missions:'Missions',
    MISSIONS:'MISSIONS',
    mission_update:'Mission est modifiée avec succès.',
    misson_error:'Le champ mission est obligatoire',
    mission_save:'la mission est enrégistrée avec succès.',
    Suppressionmission:'La mission est supprimée avec succès.',

    poNumber:'Numéro de bon de commande',
    woReference:'Ordre de travail',
    numberOfSite:'Nombre de site',
    startDate:'Date de début',
    endDate:'Date de fin',
    headOfMissions:'Chef de mission',

    zone:'Zone',
    zones:'Zones',
    ZONES:'ZONES',
    zone_update:'Zone est modifiée avec succès.',
    zone_error:'Le champ zone est obligatoire',
    zone_save:'la zone est enrégistrée avec succès.',
    Suppressionzone:'La zone est supprimée avec succès.',

    riskProfile_ad:'Profil de risque additionnel',
    riskProfile:'Profil de risque',
    riskProfiles:'Profils de risque    ',
    RISKPROFILES:'PROFIL DE RISQUE',
    riskProfile_update:'Profil de risque est modifié avec succès.',
    riskProfile_error:'Le champ profil de risque est obligatoire',
    riskProfile_save:'le profil de risque est enrégistré avec succès.',
    SuppressionriskProfile:'Le profil de risque est supprimé avec succès.',

    journey:'Trajet',
    journeys:'Trajets',
    JOURNEYS:'TRAJETS',
    journey_update:'Trajet est modifié avec succès.',
    journey_error:'Le champ trajet est obligatoire',
    journey_save:'Le trajet est enrégistré avec succès.',
    journey_continue:'Contunier votre trajet avec succès.',
    Suppressionjourney:'Le trajet est supprimé avec succès.',

    departurePlace : 'Lieu de départ',
    arrivalPlace : 'Lieu d\'arrivée',
    departureTime:  'Heure de départ',
    departurePlaceLongitude:'Longitude du lieu de départ',
    departurePlaceLatitude:'Latitude du lieu de départ',
    stopTime: 'Heure d\'arrivée',
    arrivalPlaceLongitude:'Longitude du lieu d\'arrivée',
    arrivalPlaceLatitude:'Latitude du lieu d\'arrivée',
    distance : 'Distance',
    duree : 'Durée',
    numberOfBreak : 'Nombre de pause',
    pause : 'Pause',
    purpose : 'Libelle',
    breakStartTimes : 'Début de pause',
    breakEndTimes : 'Fin de pause',
    details : 'Détails',
    interventionPlan :'Plan d\'intervention',
    interventionPlanContact :'Plan d\'intervention Contact',
    restriction : 'Restriction',
    applicator :'Applicateur',
    controller : 'Contrôleur',

    readAndApproved:'Lu et Approuvé',
    //evacuationPlans


    measure : 'Mesure de sécurité',
    measures : 'Mesures de sécurité',
    MEASURES:'MESURE DE SÉCURITÉ',
    measure_update:'La mesure de sécurité est modifiée avec succès.',
    measure_error:'Le champ mesure de sécurité est obligatoire',
    measure_save:'la mesure de sécurité est enrégistrée avec succès.',
    Suppressionmeasure:'La mesure de sécurité est supprimée avec succès.',

    evacuationPlan:'Plan d\'évacuation',
    evacuationPlans:'Plans d\'évacuations',
    EVACUATIONPLANS:'PLAN D\'ÉVACUATION',
    evacuationPlan_update:'Plan d\'évacuation est modifié avec succès.',
    evacuationPlan_error:'Le champ plan d\'évacuation est obligatoire',
    evacuationPlan_save:'le plan d\'évacuation est enrégistré avec succès.',
    SuppressionevacuationPlan:'Le plan d\'évacuation est supprimé avec succès.',
    evacuationType:'Type d\'évacuation',
    priority1:'Priorité 1',
    priority2:'Priorité 2',
    priority3:'Priorité 3',
    nearestAgency1:'Agence la plus proche 1',
    nearestAgency2:'Agence la plus proche 2',
    nearestAgency3:'Agence la plus proche 3',
    service1:'Service 1',
    service2:'Service 2',
    service3:'Service 3',
    phoneNumber1:'Numéro de téléphone 1',
    phoneNumber2:'Numéro de téléphone 2',
    phoneNumber3:'Numéro de téléphone 3',
    distanceAndDurationFromThePlaceOfAccommodation1:'Distance & durée d\'hébergemt.1',
    distanceAndDurationFromThePlaceOfAccommodation2:'Distance&durée d\'hébergemt.2',
    distanceAndDurationFromThePlaceOfAccommodation3:'Distance&durée d\'hébergemt.3',
    documents:'Documents',

    categorie : 'Catégorie',
    categories : 'Catégories',
    CATEGORIE:'CATÉGORIE',
    categorie_update:'La catégorie est modifiée avec succès.',
    categorie_error:'Le champ catégorie est obligatoire',
    categorie_save:'la catégorie est enrégistrée avec succès.',
    Suppressioncategorie:'La catégorie est supprimée avec succès.',

    question : 'Question',
    questions : 'Questions',
    QUESTION:'QUESTION',
    question_update:'La question est modifiée avec succès.',
    question_error:'Le champ question est obligatoire',
    question_save:'la question est enrégistrée avec succès.',
    Suppressionquestion:'La question est supprimée avec succès.',

    riskZoneAssessment : 'Évaluation de la zone d\'exclusion',
    riskZoneAssessments : 'Évaluations de la zone d\'exclusion',
    RISKZONEASSESSMENTS:'ÉVALUATIONS DE LA ZONE D\'EXCLUSION',
    riskZoneAssessment_update:'L\'évaluation de la zone d\'exclusion est modifiée avec succès.',
    riskZoneAssessment_error:'Le champ évaluation de la zone d\'exclusion est obligatoire',
    riskZoneAssessment_save:'L\'évaluation de la zone d\'exclusion est enrégistrée avec succès.',
    SuppressionriskZoneAssessment:'L\'évaluation de la zone d\'exclusion est supprimée avec succès.',

    riskZones:'Zones d\'exclusion',
    jobRiskAssessment_step:'Évaluation de risque',
    jobRiskAssessment : 'Évaluation de risque d\'emploi',
    jobRiskAssessments : 'Évaluations des risques d\'emploi',
    JOBRISKASSESSMENTS:'ÉVALUATIONS DES RISQUES D\'EMPLOI',
    jobRiskAssessment_update:'L\'évaluation de risque d\'emploi est modifiée avec succès.',
    jobRiskAssessment_error:'Le champ évaluation de risque d\'emploi est obligatoire',
    jobRiskAssessment_save:'L\'évaluation de risque d\'emploi est enrégistrée avec succès.',
    SuppressionjobRiskAssessment:'L\'évaluation de risque d\'emploi est supprimée avec succès.',

    initialRisk:'Risque initial',
    initialRiskSeverity:'Gravité initiale du risque',
    initialRiskProbability:'Probabilité de risque initiale',
    comapasatoryMeasures:'Mesures compensatoires',
    residualRisk:'Risque résiduel',
    residualRiskSeverity:'Gravité du risque résiduel',
    residualRiskProbability:'Probabilité de risque résiduel',
    nokiaValidation:'Validation Nokia',
    nokiaComment:'Commentaire Nokia',
    riskDetails:'Détails du risque',
    colorVrai: 'Couleur du choix vrai',
    colorFaux: 'Couleur du choix faux',
    colorNa: 'Couleur du choix néant',
    colorVrai_error:'Le champ couleur du choix vrai est obligatoire',
    colorFaux_error:'Le champ couleur du choix faux est obligatoire',
    colorNa_error:'Le champ couleur du choix faux est obligatoire',

    planification:'Missions',
    planification_cloturer:'Missions clôturées',
    planification_NonCloturer:'Missions en cours',

    mission_ref:'Référence',
    //activities
    preuve :'Preuve(e)s',
    activitie : 'Activité',
    activities : 'Activités',
    ACTIVITIE:'ACTIVITÉ',
    activitie_update:'l\'activité est modifiée avec succès.',
    activitie_error:'Le champ activité est obligatoire',
    activitie_save:'l\'activité est enrégistrée avec succès.',
    Suppressionactivitie:'La question est supprimée avec succès.',

    risk:'Risque',
    risks:'Risques',
    RISK:'RISQUE',
    risk_update:'Risque est modifié avec succès.',
    risk_error:'Le champ risque est obligatoire',
    risk_save:'le risque est enrégistré avec succès.',
    Suppressionrisk:'Le risque est supprimé avec succès.',

  
    activity:'Activité',
    riskAssessments:'Évaluations des risques',

    inactivation_teams:'Équipes en vérifications',
    activated_teams:'Équipes validées',
    rejected_teams:'Équipes rejetées',

    inactivation_vehicles :'Véhicules en vérifications',
    activated_vehicles :'Véhicules validés',
    rejected_vehicles :'Véhicules rejetés',

    inactivation_staffs:'Personnels en vérifications',
    activated_staffs:'Personnels validés',
    rejected_staffs:'Personnels rejetés',

    unclosedMissions:'Missions en cours',
    enclosedMissions:'Missions cloturées',

    inactivation_liftingEquipments:'Outils de levage en vérifications',
    activated_liftingEquipments:'Outils de levage validés',
    rejected_liftingEquipments:'Outils de levage rejetés',

    inactivation_equipments:'EPI en vérifications',
    activated_equipments:'EPI validés',
    rejected_equipments :'EPI rejetés',
    image_error:'Le champ image est obligatoire',
    name_file1_3:'Le fichier  est obligatoire',

    project:'Projet',
    projects:'Projets',
    PROJECTS:'PROJETS',
    project_update:'Projet est modifié avec succès.',
    project_error:'Le champ projet est obligatoire',
    project_save:'le projet est enrégistré avec succès.',
    Suppressionproject:'Le projet est supprimé avec succès.',

    pessNokia: 'PESS NOKIA',
    rom:'ROM',
    qhsNokia:'QHS NOKIA',
    leadProject:'CHEF PROJET',
    name:'Nom complet',

    pessNokia_error:'Le champ pessnokia est obligatoire',
    rom_error:'Le champ rom est obligatoire',
    qhsNokia_error:'Le champ qfh nokia est obligatoire',
    name_error:'Le champ nom complet est obligatoire',
    leadProject_error:'Le champ lead projet est obligatoire',
    description_error:'Le champ description est obligatoire',

    raison:'Raison',
    raison_error:'Le champ projet est obligatoire',

    scheduled_date:'Date programmée',
    scheduled_date_error:'Le champ projet est obligatoire',

    meetings:'Réunions',
    is_satisfied :'Satisfaction',
    Suppressionmeeting:'La réunion est supprimée avec succès.',

    reserve_comment:'Commentaire',
    reserve_type:'Type de reserve',

    reserve_comment_error:'Le champ commentaire est obligatoire',
    reserve_type_error:'Le champ type de reserve est obligatoire',

    purchase:'Bon de commande',
    purchase_error:'Le champ bon de commande est obligatoire',
    purchase_save:'le bon de commande est enrégistré avec succès.',
    purchase_update:'le bon de commande est modifié avec succès.',
    Suppressionpurchases:'le bon de commande est supprimé avec succès.',
 
    orderOfWorks:'Ordre de travail',
    orderOfWork_error:'Le champs ordre de travail est obligatoire.',
    orderOfWork_save:'L\'ordre de travail est enrégistré avec succès.',
    orderOfWork_update:'L\'ordre de travail est modifié avec succès.',
    SuppressionorderOfWork:'L\'ordre de travail est supprimé avec succès.',
    validations:'Validations',

    libraries:'Bibliothèques',
    library:'Bibliothèque',
    LIBRARY:'BIBLIOTHÈQUE',
    library_error:'La bibliothèque est obligatoire.',
    library_save:'La bibliothèque est enrégistrée avec succès.',
    library_update:'la bibliothèque est modifiée avec succès.',
    Suppressionlibrary:'la bibliothèque est supprimée avec succès.',

    teamLeader:'Team Leader Sous-traitant',
    leader:'Chef',

    securityQuestion:'Enumérer un risque sécuritaire',
    securityQuestions:'Questionnaire sécurité',
    SECURITYQUESTION:'QUESTIONNAIRE SÉCURITE',
    securityQuestions_error:'L\'énumération du risque sécuritaire est obligatoire.',
    securityQuestions_save:'Le risque sécuritaire est enrégistrée avec succès.',
    securityQuestions_update:'la risque sécuritaire est modifiée avec succès.',
    SuppressionsecurityQuestions:'le risque sécuritaire est supprimée avec succès.',

    siteGroup:'Type d\'évaluation sécuritaire',
    securityEvaluation:'Évaluation sécuritaire',
    securityEvaluations:'Évaluations sécuritaires',
    SECURITYEVALUATION:'ÉVALUATION SÉCURITAIRE',
    securityEvaluations_error:'L\'évaluation sécuritaire est obligatoire.',
    securityEvaluations_save:'L\'évaluation sécuritaire est enrégistrée avec succès.',
    securityEvaluations_update:'L\'évaluation sécuritaire est modifiée avec succès.',
    SuppressionsecurityEvaluations:'L\'évaluation sécuritaire est supprimée avec succès.',

    eva_instable:'ÉVALUATION DE L\'INSTABILITÉ',
    eva_vunerable:'ÉVALUATION DE LA VULNÉRABILITÉ',
    eva_consequance:'ÉVALUATION DES CONSÉQUENCES',
    eva_probabilite:'ÉVALUATION DE LA PROBABILITÉ',
  
    timing:'Timing',
    feasibility:'Faisabilité',
    achievableInMoreFavorablePeriod:'Réalisable dans une periode plus propice',
    notFeasibleOverLongPeriod:'Non réalisable sur une longue periode',
    initializeCmt:'Initialiser un CMT',
    comment:'Recommandation',
    score:'Score',
    final_result:'Résultat final',

    mission_error:'Le champ mission est obligatoire',
    timing_error:'Le champ timing est obligatoire',
    siteGroup_error:'Le champ type d\'évaluation est obligatoire',
    comment_error:'Le champ recommandation est obligatoire',

    securityEvalution_error:'l\'évaluation sécuritaire est obligatoire.',
    securityEvalution_save:'l\'évaluation sécuritaire est enrégistrée avec succès.',
    securityEvalution_update:'l\'évaluation sécuritaire est modifiée avec succès.',
    SuppressionsecurityEvalution:'l\'évaluation sécuritaire est supprimée avec succès.',

    //New wording

    reporting:'Reporting',
    validate_trajet:'Validation Trajet',
    mission_status:'Statut de la mission',

    Date_début:'Date début',
    Date_fin:'Date fin',

    phoneNumber: 'Le champ téléphone est obligatoire',
    comfirm_validation:'Confirmer la validation?',
    valide_equiment :'Equipement validé avec succès.',
    echec_equiment :'Validation échouée.',
    rejet_equiment :'Rejet validé avec succès',
    Voir: 'Détail',
    autre_norme:'Autre norme',
    changer:'Changer',
    effacer:'Effacer',
    ajouter_Image:'Ajouter Image',
    modifier_Image:'Modifier Image',
    valider_equipement:'Valider l\'équipement',
    rejeter_equipement:'Rejeter l\'équipement',
    rapport:'Rapport',
    details_du_rejet:'Détails du rejet',
    ajouter_votre_commentaire:'Ajouter votre commentaire',
    type:'Type',
    num_plaque:'N° de plaque',
    carte_grise:'Carte grise',
    modele:'Modèle',
    image:'Image(s)',
    valider_véhicule:'Valider le véhicule',
    rejeter_véhicule:'Rejeter le véhicule',
    images_vehicle:'Images du vehicle',
    Nombre_ceinture_sécurité_02:'Nombre de ceinture de sécurité 02',
    Nombre_ceinture_sécurité_03:'Nombre de ceinture de sécurité 03',
    air_bague:'Airbag',
    date_mise_circulation:'Date de mise en circulation ',
    compagnie_assurance:'Compagnie d\'assurance ',
    type_assurance:'Type d\'assurance',
    num_assurance:'Numéro d\'assurance',
    date_expiration:' Date d\'expiration ',
    dernière_visite_technique:'Dernière visite technique',
    date_visite_technique_venir:'Date de visite technique à venir ',
    date_maitenance_venir:'Date de maitenance à venir',
    suivi_ivms:'Suivi IVMS ',
    Kilomètrage:'Kilomètrage',
    rapport_ivms:'Rapport IVMS',
    carnet_entretien:'Carnet d\'entretien',
    Ajout_Nouvel:'Ajout Nouvel',
    choisir:'Choisir',
    type_error:  'Le champ type est obligatoire',
    plateNumber_error:'Le champ numéro de plaque est obligatoire',
    greyCard_error:'Le champ catre grise est obligatoire',
    numberOf03PointsSeatBelt_error: 'Le champ nombre de place 03 est obligatoire',
    numberOf02PointsSeatBelt_error: 'Le champ nombre de place 02 est obligatoire',
    frontAirbag_error:  'Le champ Airbag est obligatoire',
    firtsReleaseDate_error: 'Le champ première date de sortie est obligatoire',
    insuranceCompany_error: 'Le champ compagnie  d\'assurance est obligatoire',
    insurancePolicyNumber_error: 'Le champ numéro d\'assurance est obligatoire',
    typeOfInsuranceContract_error:'Le champ type de contrat d\'assurance est obligatoire',
    expiryDate_error:  'Le champ date d\'expiration est obligatoire',
    lastTechnicalVisitDate_error: 'Le champ dernière visite est obligatoire',
    nextTechnicalVisitDate_error:'Le champ date de visite technique à venir  est obligatoire',
    ivmsReport_error:  'Le champ rapport IVMS est obligatoire',
    dateOfTheNextMaintenanceVisit_error:  'Le champ date de la visite suivante est obligatoire',
    ivmsTracker_error: 'Le champ suivie IVMS est obligatoire',
    model_error: 'Le champ modèle est obligatoire',
    killometerOnTheDashboard_error:'Le champ kilomètrage visite est obligatoire',
    maintenanceNotebook_error:'Le champ carnet de maintenance est obligatoire',
    valide_vehicule:'Véhicule validé avec succès.',
    echec_validation_vehicule:'Validation échouée.',
    rejete_vehicule:'Rejet validé avec succès.',
    echec_rejet_vehicule: 'Rejet a échoué.',

    valider_personnel:'Valider le personnel',
    rejeter_personnel:'Rejeter le personnel',
    contractType :'Type de contrat ',
    cardType:'Type de carte',
    idCardOrPassportNumber:'N° de Carte ou Passport',
    externalStaffOwnedByAnotherCompany:'Personnel externe appartenant à une autre entreprise',
    seniorityInTheCompany:'Ancienneté dans l\'entreprise',
    seniorityInTheFunction:'Ancienneté dans la fonction',
    externalCompanyName:'Nom de l\'entreprise externe',
    externalCompanyContractReference:'Référence du contrat d\'entreprise externe',
    drivingLicenseID:'Identifiant du permis de conduire',
    seniorityInDriving:'Ancienneté en conduite ',
    medicalCheck:'contrôle médical',
    medicalCheckDate:'Date du contrôle médical',
    preuve_medicale:'Preuve médicale',

    informations_formation :'Informations sur la formation',
    dateOfObtainings:'Date de la formation',
    trainerOrganisms_tab:'Organisme formateur',
    idCertificateReferences:'Référence du certificat',
    validityDates:'Dates de validité',
    dateOfTheNextUpgrades:'Prochaine mise à niveau',
    date_formation:'Date de formation',
    date_obtention:'Date d\'obtention',
    organisme_formateur:'Organisme formateur',

    référence_certificat_identification:'Référence du certificat d\'identification',
    date_validite:'Date de validité',
    prochaine_mise_niveau:'Prochaine mise à niveau',

    valide_staff:'Véhicule validé avec succès.',
    echec_validation_staff:'Validation échouée.',
    rejete_staff:'Rejet validé avec succès.',
    echec_rejet_staff: 'Rejet a échoué.',
    formation_add:'Formation ajoutée à la liste',
    formation_add_error:'La formation existe déjà dans la liste',
    formation_delete:'Formation supprimée de la liste',
    formation_update_up:'Formation modifiée',
    formation_vide:'liste de formation vide',

   
    fullName_error:'Le champ nom complet  est obligatoire',    
    externalCompanyContractReference_error:'Le champ référence du contrat d\'entreprise externe  est obligatoire',     
    medicalCheckDate_error:'Le champ date du contrôle médical est obligatoire',     
    contractType_error: 'Le champ type de contrat est obligatoire',       
    cardType_error:  'Le champ type de carte est obligatoire',      
    idCardOrPassportNumber_error: 'Le champ numéro de carte d\'identité ou de passeport est obligatoire',       
    externalStaffOwnedByAnotherCompany_error:  'Le champ personnel externe appartenant à une autre entreprise est obligatoire',      
    birthdate_error: 'Le champ date de naissance est obligatoire',      
    seniorityInTheCompany_error:  'Le champ ancienneté dans l\'entreprise est obligatoire',        
    job_function_error: 'Le champ fonction est obligatoire',      
    seniorityInTheFunction_error: 'Le champ ancienneté dans la fonction est obligatoire',      
    externalCompanyName_error: 'Le champ nom de l\'entreprise externe est obligatoire',      
    drivingLicenseID_error:  'Le champ identifiant du permis de conduire est obligatoire',      
    seniorityInDriving_error:  'Le champ ancienneté en conduite est obligatoire',      
    medicalCheck_error:  'Le champ contrôle médical est obligatoire',       
    formations_error:  'Le champ formtion est obligatoire',        
    dateOfTrainings_error: 'Le champ date de formation est obligatoire',        
    dateOfObtainings_error:'Le champ date d\'obtention est obligatoire',      
    trainerOrganisms_error: 'Le champ organisme formateur est obligatoire',      
    idCertificateReferences_error: 'Le champ référence du certificat d\'identification est obligatoire',
    validityDates_error: 'Le champ date de validité est obligatoire',
    dateOfTheNextUpgrades_error: 'Le champ date des prochaines mises à niveau est obligatoire',

    add_personne:'Ajouter un Personnel',
    update_personne:'Modifier un Personnel',
    detail_personnne:'Détails du Personnel',
    filtre:'Filtre',

    ajouter_vehicle:'Ajouter un véhicule',
    modifier_vehicle:'Modifier un véhicule',
    details_vehicle:'Détails du véhicule',

    valider_equipe:'Valider équipe',
    rejeter_equipe:'Rejeter équipe',
    ajouter_equipe:'Ajouter une équipe',
    modifier_equipe:'Modifier une équipe',
    details_equipe:'Détails de l\'équipe',

    valide_equipe:'Equipe validée avec succès.',
    echec_validation_equipe:'Validation échouée.',
    rejete_equipe:'Rejet validé avec succès.',
    echec_rejet_equipe: 'Rejet a échoué.',
    Suppressionequipe:'L\'équipe est supprimée avec succès.',
    equipe_error:'L\'équipe est obligatoire.',
    equipe_save:'L\'équipe est enrégistrée avec succès.',
    equipe_update:'L\'équipe est modifiée avec succès.',
    equipe_name:'Nom de l\'équipe',
    Valider:'Valider',

    teamLeader_error:'Le champ team Leader Sous-traitant est obligatoire',
    ajouter_projet:'Ajouter un projet',
    modifier_projet:'Modifier un projet',
    details_projet:'Détails du projet',
    creation_bon_com:'Création de bon de commande et d\'orde de travail',
    bon_comds:'Bon de commande(s)',
    bon_comd:'Bon de commande',
    ords_travail:'Ordre(s) de travail',
    ord_travail:'Ordre de travail',
    info_assignation:'Informations sur l\'assignation ',
    sous_traitant:'Sous traitant',
    assignations:'Assignation(s)',
    save_site_up_error:'Cette ligne de (site,compagnie et du bon de commande) existe dans la liste, Veuillez procéder à la suppression pour des raisons de sécurité et recréer',
    save_task_add_error:'Cette ligne de (site,compagnie et du bon de commande) existe dans la liste, Veuillez procéder à modification de(s) Tâche(s) ajoutée(s) à la liste',
    site_deleted_liste:'Site supprimé de la liste',
    site_existe_liste:'Le groupe de site existe déjà dans la liste',
    site_add_liste:'Groupe de site ajouté à la liste',
    site_verification_liste:'Vérifier les informations',
    up_formation:'Formation modifié',
    add_site_pro:'Site ajouté à la liste',
    add_site_pro_add:'Le site existe déjà dans la liste',
    site_obligatoire:'Champ site est obligatoire',
    
    ord_travail_error:'Champs numéro d\'ordre obligatoire',
    bon_comd_error:'Champs numéro de bon obligatoire',

    voir_mission:'Voir la mission',
    modifier_mission:'Modifier la mission',
    supprimer_mission:'Supprimer la mission',
    confirmer_vehicule:'Confirmer le(s) véhicule(s)',
    creation_mission:'Création de Mission',
    etape_1:'Etape N°1',

    continuer_trajet:'Continuer le trajet',
    voir_trajet:'Voir le trajet',
    modifier_trajet:'Modifier le trajet',
    supprimer_trajet:'Supprimer le trajet',
    creation_trajet:'Création de Trajet',
    etape_2:'Etape N°2',

    voir_evacuation:'Voir le plan d\'évacuation',
    modifier_evacuation:'Modifier le plan d\'évacuation',
    supprimer_evacuation:'Supprimer le plan d\'évacuation',
    creation_evacuation:'Création de plan d\'évacuation',
    etape_3:'Etape N°3',

    voir_zone_exclusion:'Voir la zone d\'exclusion',
    modifier_zone_exclusion:'Modifier la zone d\'exclusion',
    supprimer_zone_exclusion:'Supprimer la zone d\'exclusion',
    creation_zone_exclusion:'Création la zone d\'exclusion',
    etape_4:'Etape N°4',

    voir_evaluation_risque:'Voir l\'evaluation de risque',
    modifier_evaluation_risque:'Modifier l\'evaluation de risque',
    supprimer_evaluation_risque:'Supprimer l\'evaluation de risque',
    creation_evaluation_risque:'Création l\'evaluation de risque',
    etape_5:'Etape N°5',

    non_démarée:'Non démarée',
    rejetée:'Rejetée',

    opération_échouée:'Opération échouée',
    confirmer_clôture:'Confirmer la clôture?',
    rejeter_mission:'Rejeter mission',
    confirmer_rejet:'Confirmer le rejet?',
    mission_rejete:'Mission rejetée',
    échoué:'échoué',
    confirmation_trajet:'Confirmation trajet',
    confirmer_validation:'Confirmer la validation?',
    trajet_validé:'Trajet validé',
    rejet_trajet:'Rejet trajet',
    trajet_rejeté:'Trajet rejeté',

    suppression_mission:'Confirmer la suppression de la mission?',
    suppression_valid_mission:'La mission est supprimée avec succès.',
    suppression_error_mission:'La suppression a échoué.',

    suppression_evacuation: 'Confirmer la suppression du plan d\'évacuation?',
    suppression_valid_evacuation:'Le plan est supprimé avec succès.',
    suppression_error_evacuation:'La suppression a échoué.',

    suppression_trajet: 'Confirmer la suppression du trajet?',
    suppression_valid_trajet:'Le trajet est supprimé avec succès.',
    suppression_error_trajet:'La suppression du trajet a échoué.',

    suppression_zone_exclusion:'Confirmer la suppression de la zone d\'exclusion?',
    suppression_valid_zone_exclusion:'L\'évaluations de la zone de risque est supprimée avec succès.',
    suppression_error_zone_exclusion:'La suppression a échoué.',

    suppression_evaluation_risque: 'Confirmer la suppression de l\'évaluations des risques?',
    suppression_valid_evaluation_risque:'L\' évaluations des risques d\'emploi est supprimée avec succès.',
    suppression_error_evaluation_risque:'La suppression a échoué.',
    
    mission_clôturée :'Mission clôturée',
    carte:'Carte',
    alerte:'Alerte',

    valider_risques:' Valider les risques',
    rejet_trajet_:'Rejeter trajet (Nokia)',
    clôturer_mission:'clôturer mission',
    valider_trajet_:'Valider trajet (Nokia)',

    voir_evaluation_securitaire:'Voir l\'evaluation sécuritaire',
    info_equipe:'Informations sur l\'équipe',
    prod_op:'Procédures opérationnelles',
    bref_sec:'Briefing de sécurité',
    pres_hse:'Présentation de la boîte à outils HSE',
    confirmation:'Confirmation',
    otherMeansOTransport:'Autre moyen transport',
    fiche_insp:'Fiche(s) d\'inspection(s) du véhicule, et autres documents requis',
    name_file:'Nom du fichier',
    reunion_etat:'Réunion satisfaisante',
    demande_etat:'Cochez si la demande a été satisfaite.',
    comite_sécurite:'Comité de sécurité',
    question_modif_equipe:'Voulez-vous modifier l\'équipe sélectionnée?',
    question_ajout_equipe:'Voulez-vous ajouter une nouvelle l\'équipe?',
    comfirm_vehicule:'Confirmation du véhicule',
    retour:'Retour',
    name_file1_3_error: 'Le fichier  est obligatoire',
    poNumber_error: 'Le champ numéro de commande  est obligatoire',
    woReference_error:  'Le champ ordre de travail  est obligatoire',
    numberOfSite_error:  'Le champ nom nombre de site est obligatoire',
    endDate_error: 'Le champ date de fin  est obligatoire',
    startDate_error:'Le champ date de début  est obligatoire',
    tasks_missions_error:'Le champ tâches est obligatoire',
    headOfMissions_error:'Le champ chef de mission est obligatoire',


    pro_operationnelle_oui: 'Procédures opérationnelles confirmées',
    pro_operationnelle_non: 'Procédures opérationnelles non confirmées',
    bref_sec_conf_oui: 'Briefing de sécurité confirmé',
    bref_sec_conf_non: 'Brieffing sécuritaire non confirmé',
    pre_boite_oui: 'Toolbox talk HSE confirmée',
    pre_boite_non: 'Toolbox talk HSE non confirmée',
    send_mail: 'Mail envoyé',
    comf_vehicule: 'Voiture confirmée',
    comf_vehicule_error: 'ERREUR DE CONFIRMATION',
    delete_file:'Fichier supprimé de la liste',

    save_mission:'La mission  est enrégistrée avec succès.',
    update_mission:'La mission est modifiée avec succès.',
    echec_mission_save :'ENREGISTREMENT ÉCHOUÉ',
    save_error_team:'ENREGISTREMENT',

    detail_pause:'Détails de la pause',
    longitude_error:'Longitude invalide.',
    latitude_error:'Latitude invalide.',
    definition_pause:'Définissez la pause ',
    il_vous_reste:'Il vous reste',
    pause_obligatoire:'pause(s) obligatoires à planifier',
    heure_definie:'L\'heure définie n\'est pas autorisée',
    
    pauses:'Pause(s)',
    interventionPlanContact_error: 'Le champ Plan d\'intervention contact est obligatoire',
    departurePlace_error:  'Le champ lieu de départ est obligatoire',
    departurePlaceLatitude_error:  'Le champ Latitude du lieu de départ  est obligatoire',
    arrivalPlace_error: 'Le champ lieu d\'arrivé est obligatoire',
    arrivalPlaceLongitude_error:'Le champ Latitude du lieu d\'arrivé est obligatoire',
    arrivalPlaceLatitude_error:'Le champ Longitude du lieu d\'arrivé est obligatoire',
    departureTime_error: 'Le champ l\'heure de depart est obligatoire',
    stopTime_error: 'Le champ l\'heure d\'arriver est obligatoire',
    distance_error: 'Le champ distance est obligatoire',
    duree_error:'Le champ durée est obligatoire',
    numberOfBreak_error:'Le champ nombre de pause est obligatoire',
    pause_error:'Le champ pause est obligatoire',
    purpose_error:'Le champ but est obligatoire',
    sites_error: 'Le champ site est obligatoire',
    breakStartTimes_error: 'Le champ début de pause est obligatoire',
    breakEndTimes_error:'Le champ fin de pause est obligatoire',
    details_error:'Le champ détail est obligatoire',

    pause_add:'Pause ajoutée à la liste',
    error:'ERREUR',
    attention:'ATTENTION',

    evacuation_save: 'Le plan  est enrégistré avec succès.',
    evacuation_update : 'Le plan est modifié avec succès.',
    Suppressionevacuation:'Le plan est supprimé avec succès.',

    site_evalue:'Site(s) évalué(s)',
    all_delete:'Tout supprimer',

    voir_evaluation:'Voir l\'évaluation sécuritaire de zone d\'opération',
    add_eva_sec:'Ajouter une évaluation sécuritaire de zone d\'opération',
    edit_sec:'Modifier l\'évaluation sécuritaire de zone d\'opération',

    risque_emploie:'L\' évaluations des risques d\'emploi est supprimée avec succès.',
    echec_risque_emploie:'La suppression a échoué.',
    risque_emploie_save:'L\' évaluations des risques d\'emploi  est enrégistrée avec succès.',
    risque_emploie_update:'L\'évaluations des risques d\'emploi est modifiée avec succès.',

    texte_validation_evaluation :'Confirmez la validation des évaluations des risques d\'emploi.',
    validation_succes:'Validation avec succès.',
    validation_echec:'La validation a échoué.',
    nombre_pause_suff:'Nombre de pauses suffisantes',
    nombre_pause_suppr:'Pause supprimée de la liste',
    pause_update:'Pause modifiée',
    pause_one_texte:'Vous avez droit à',
    pause_tow_texte:'pause(s) obligatoire(s) à planifier',

    oui:'Oui',
    non:'Non',
    select_update:'Séléctionnez un site pour modifier',
    select_show:'Séléctionnez un site pour les détails',
    questionnaire:'Questionnaires',
    quesion_update:'Questionnaire modifiée',

    list_site:'la liste des site(s) validé(s) est vide',
    libelle_error:'Champ libelle obligatoire',
    site_insuf:'la liste des site(s) évalué(s) est insuffisant',
    risk_residuel:'Risques résiduels',
    risk_r1:'Risque (R)',
    risk_r2:'est calculé comme le produit de la',
    risk_r3:'Probabilité (P)',
    risk_r4:'et de la',
    risk_r5:'Conséquence (C)',
    axe_vertical:'Axe vertical = Conséquence (C)',
    axe_horizontal:'Axe horizontal = Probabilité (P)',
    graduation:'Graduation : [1-5]',
    legende:'LEGENDE :',
    negligeable_1:'1= Négligeable',
    modere_2:'2= Modéré',
    eleve_3:'3= Élevé',
    considerable_4:'4= Considérable',
    extreme_5:'5= Extrême',

    ajouter_votre_commentaire_QHS:'Ajouter votre commentaire QHS',
    ajouter_votre_commentaire_PESS:'Ajouter votre commentaire PESS',
    ajouter_votre_commentaire_CHEF:'Ajouter votre commentaire CHEF PROJET',
    fichier_obligatoire:'Fichier obligatoire',
    fichier:'Fichier',

    manager:'Responsable Sous-traitant',
    manager_error:'Le champ responsable Sous-traitant est obligatoire',
    comonTransport:'Transport en commun',
    comonTransportDetails:'Détails du transport',

    comonTransport_error:'Le champ de transport en commun est obligation ',
    comonTransportDetails_error:'Le champ détails du transport est obligation ',

    rental:'Voiture de location ',
    rentalCompany:'Société de location',
    rentalCompanyContratReference :'Référence du contrat de la société de location',

    rental_error:'Champ de voiture de location est obligation ',
    rentalCompany_error:'Société de location est obligation ',
    rentalCompanyContratReference_error :'Référence du contrat de la société de location est obligation ',

    Assignations:'Assignations',
    ajouter_assignation:'Ajouter/Modifier l\'assignation',
    modifiction_assignation:'Modification l\'assignation',
    details_assignation:'Détails de l\'assignation',
    add_modifier:'Ajouter/Modifier'

  }
}